import React from 'react'
import Icon from '../Icon';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

type TrialPeriodFeatures = {
  onCancel?: () => void;
  item?: any
  featuresList?: any
  title?: any
  onNext?: any
};

const TrialPeriodFeatures = ({ onCancel, item, featuresList, title, onNext }: TrialPeriodFeatures) => {
  const router = useRouter();
  // dispatchEvent

  const { t } = useTranslation('translation');

  const data = [
    {
      description: t('pricing1_desc')
    },
    {
      description: t('pricing2_desc')
    },
    {
      description: t('pricing3_desc')
    },
    {
      description: t('pricing4_desc')
    },
    {
      description: t('pricing5_desc')
    },
    {
      description: t('academic_assist')
    },
    {
      description: t('knowledge_across')
    },
    {
      description: t('goalProgress_tracking')
    },
    {
      description: t('multi_Platform')
    },
  ]

  const nextHandler = () => {
    onNext();
    const destination = router?.query?.isMobile ? `/checkout?isMobile=${router.query.isMobile}` : '/checkout'
    router.push(destination)
  }

  return (
    <div className="p-10 pt-5 lg:px-8 md:pt-6 md:px-5 md:pb-6 max-w-[768px] overflow-hidden">
      <div className="flex items-center justify-between mb-3">
        <h2 className='h4 !font-bold !!font-mont'>{item.title} {t('trial_feature')}</h2>
        <h2 className='h4 !font-bold !!font-mont'>{item?.price} $</h2>
      </div>
      <hr className='mb-4 border-n-3 -mx-10' />
      {/* <div className='flex items-center gap-3 mb-6'>
        <div className='flex justify-center items-center w-5 h-5 bg-primary-1 rounded-full'>
          <span className='w-2 h-2 bg-white rounded-full'></span>
        </div>
        <div className='flex flex-col gap-1'>
          <span className='text-sm font-semibold'>{t('trial_period')}</span>
          <span className='text-sm font-semibold text-[#3FDD78]'>{t('free_7days')}</span>
          <span className='text-sm font-semibold'>{t('then')} {item?.price} $</span>
        </div>
      </div> */}
      {/* {
        featuresList?.map((item: any, i: any) => (
          <div key={i} className='flex items-center gap-2 mb-2'>
            <Icon name="check" fill='#0084FF' className='mb-0 flex justify-center items-center' />
            <span className='text-sm font-semibold'>{item}</span>
          </div>
        ))
      } */}
      {featuresList?.map((item: any) => (
        <div
          className="flex items-center py-2.5 w-full"
          key={item?.id}
        >
          <div className="flex shrink-0 base2 gap-2 font-semibold">
            <Icon
              className={`fill-primary-1 flex-shrink-0`}
              name={"check-thin"}
            /> <span>{item?.title}</span>
            { }
            {(title === 'Basic') &&
              item.basic && <span className='font-normal'>({item.basic})</span>}
            {(title === 'Premium') &&
              item.premium && <span className='font-normal'>({item.premium})</span>}
            {(title === 'Pro') &&
              item.prO && <span className='font-normal'>({item.prO})</span>}
          </div>
        </div>
      ))}
      <button
        className='btn-blue w-full mt-8 flex justify-between'
        onClick={nextHandler}
      >

        {t('continue')}
        <span className='w-[1.375rem] h-[1.375rem] bg-white flex !font-mont justify-center items-center rounded-full'>
          {/* <Icon name="rightArrow" className='w-3 h-3 flex-shrink-0' fill='#0084FF'/> */}
          <svg className='flex-shrink-0' width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.92 6.62019C11.8724 6.49743 11.801 6.38529 11.71 6.29019L6.71 1.29019C6.61676 1.19695 6.50607 1.12299 6.38425 1.07253C6.26243 1.02207 6.13186 0.996094 6 0.996094C5.7337 0.996094 5.4783 1.10188 5.29 1.29019C5.19676 1.38342 5.1228 1.49411 5.07234 1.61594C5.02188 1.73776 4.99591 1.86833 4.99591 2.00019C4.99591 2.26649 5.1017 2.52188 5.29 2.71019L8.59 6.00019H1C0.734784 6.00019 0.48043 6.10554 0.292893 6.29308C0.105357 6.48062 0 6.73497 0 7.00019C0 7.2654 0.105357 7.51976 0.292893 7.70729C0.48043 7.89483 0.734784 8.00019 1 8.00019H8.59L5.29 11.2902C5.19627 11.3831 5.12188 11.4937 5.07111 11.6156C5.02034 11.7375 4.9942 11.8682 4.9942 12.0002C4.9942 12.1322 5.02034 12.2629 5.07111 12.3848C5.12188 12.5066 5.19627 12.6172 5.29 12.7102C5.38296 12.8039 5.49356 12.8783 5.61542 12.9291C5.73728 12.9798 5.86799 13.006 6 13.006C6.13201 13.006 6.26272 12.9798 6.38458 12.9291C6.50644 12.8783 6.61704 12.8039 6.71 12.7102L11.71 7.71019C11.801 7.61508 11.8724 7.50294 11.92 7.38019C12.02 7.13672 12.02 6.86365 11.92 6.62019Z" fill="#0084FF" />
          </svg>

        </span>
      </button>
    </div>
  )
}

export default TrialPeriodFeatures